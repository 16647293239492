@use "../../scss/variables.scss" as vars;

.modal {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  //   border: 1px solid red;
  background: rgba(0, 0, 0, 0.2);
}
.bodyModal {
  //   border: 1px solid green;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  // height: 100%;
  //   width: 90vw;
}
.modalBody {
  //   height: 60vh;
  width: 60vw;
  border-radius: 20px;
  overflow: hidden;
  z-index: 2;
  background-color: black;
  //   padding: 1.5rem;
  @media screen and (max-width: 800px) {
    width: 95vw;
    // height: 65vh;
  }
}
.body {
  // height: 90vh;
  // width: 90vw;
  //   border: 1px solid blue;
  overflow: hidden;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //   background-color: white;

  overflow: hidden;
}

.closeButton {
  transition: transform 0.35s;
  background-color: red;
  height: 30px;
  width: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 20px;
  z-index: 2;

  &:hover {
    transform: scale(1.2);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  background-color: black;
  overflow: hidden;
  color: white;
  height: 100%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 1.5rem;

  @media screen and (max-width: 800px) {
    min-height: 50vh;
  }

  .inputRow {
    height: 30px;
    .title {
      height: 30px;
    }
    img {
      height: 22px;
      width: 22px;
    }
  }
  .submitBtn {
    height: 40px;
    border-radius: 20px;
    background-color: vars.$color-dlcGreen;
    border: none;
  }
}

.inputContainer {
  border-bottom: 1px solid white;
  .inputField {
    height: 20px;
    background-color: black;
    //   border: 0px;
    color: white;
    width: 100%;
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    outline: none;
    &:focus {
      outline: none;
    }
  }
}
.toggleInput {
  display: none;
}
.toggle {
  position: relative;
  width: 40px;
  height: 26px;
  background-color: #ccc;
  border-radius: 50px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

/* Stile della manopola */
.toggleKnob {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  transition: left 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Stile del toggle attivo */
.toggleInput:checked + .toggle {
  background-color: vars.$color-dlcGreen; /* Verde stile iPhone */
}

.toggleInput:checked + .toggle .toggleKnob {
  left: 18px; /* Sposta la manopola a destra */
}
