@use "../../scss/variables.scss" as vars;

.modal {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  //   border: 1px solid red;
  background: rgba(0, 0, 0, 0.2);
}
.bodyModal {
  //   border: 1px solid green;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  // height: 100%;
  //   width: 90vw;
}
.modalBody {
    // min-height: 30vh;
  width: 60vw;
  border-radius: 20px;
  overflow: hidden;
  z-index: 2;
  background-color: black;
  border:1px solid white;
  padding: 1.5rem;
  @media screen and (max-width: 800px) {
    width: 95vw;
    // height: 65vh;
  }
}
.body {
//   min-height: 40vh;
  // width: 90vw;
  //   border: 1px solid blue;
  overflow: hidden;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //   background-color: white;

  overflow: hidden;
}

.closeButton {
  transition: transform 0.35s;
  background-color: red;
  height: 30px;
  width: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 20px;
  z-index: 2;

  &:hover {
    transform: scale(1.2);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  overflow: hidden;
  color: black;
  height: 100%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 1.5rem;

  @media screen and (max-width: 800px) {
    min-height: 50vh;
  }
}
