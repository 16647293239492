.pageContainer {
//   height: 100vh;
  .boxContainer {
    height: 100%;
    color: white;
  }

  .signin {
    text-decoration: underline;
    color: #b2fc02;
  }

  .inputField {
    height: 40px;
    border-radius: 20px;
    border: 1px solid white;
    background-color: black;
    color: white;
    padding: 2rem;
    outline: none;
  }

  .submitBtn {
    color: black;
    background-color: #b2fc02;
    border-radius: 20px;
    height: 50px;
    border: none;
  }

  .logoContainer {
    // border: 1px solid red;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1.5rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //   border:1px solid green;
    height: 30px;
  }

  /* Hide the browser's default checkbox */
  .checkboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    border-radius: 10px;
    // border:1px solid red;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .checkboxContainer:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .checkboxContainer input:checked ~ .checkmark {
    background-color: #b2fc02;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkboxContainer input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkboxContainer .checkmark:after {
    left: 10px;
    top: 2px;
    width: 10px;
    height: 20px;
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
