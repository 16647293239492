@use "../../scss/variables.scss" as vars;

.modal {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  //   border: 1px solid red;
  background: rgba(0, 0, 0, 0.85);
}
.bodyModal {
  //   border: 1px solid green;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  // height: 100%;
  //   width: 90vw;
}
.modalBody {
  //   height: 60vh;
  width: 60vw;
  border-radius: 20px;
  overflow: hidden;
  z-index: 2;
  background-color: white;
  padding: 1.5rem;
  @media screen and (max-width: 800px) {
    width: 95vw;
  }
}
.body {
  // height: 90vh;
  // width: 90vw;
  //   border: 1px solid blue;
  overflow: hidden;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //   background-color: white;

  overflow: hidden;
}

.closeButton {
  transition: transform 0.35s;
  background-color: vars.$color-dark_grey_50;
  height: 30px;
  width: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: 30px;
  right: 30px;
  border-radius: 20px;
  z-index: 2;

  &:hover {
    transform: scale(1.2);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  background-color: vars.$color-light_grey_10;
  overflow: hidden;
  height: 100%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 1.5rem;
  //   border: 1px solid red;

  @media screen and (max-width: 800px) {
    min-height: 50vh;
  }
  .downloadContactBtn {
    height: 40px;
    border-radius: 20px;
    // width: 100%;
    border: 1px solid black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: vars.$color-dlcGreen;
    color: black;
    &:hover {
      transform: scale(1.1);
      transition-duration: 300ms;
    }
  }
  .inputContainer {
    border-bottom: 1px solid black;
    input {
      height: 30px;
      border-radius: 20px;
      border: none;
      // width: 100%;
      padding: 1.5rem;
      background-color: transparent;
      outline: none;
    }
  }
  .submitBtn {
    height: 40px;
    border: none;
    border-radius: 20px;
    background-color: vars.$color-dlcGreen;
    padding: 1.5rem;
  }
}
