@use "../../scss/variables.scss" as vars;

.pdfContainer {
  position: relative;
  .pdfBox {
    // height: 200px;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid black;
    height: 175px;
    // padding: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    z-index: 1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .zoomBtn {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      color: white;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5);
      img {
        height: 20px;
        width: 20px;
        object-fit: cover;
      }
    }
    .deleteBtn {
      position: absolute;
      top: -10px;
      right: -10px;
      color: white;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 0, 0, 1);
      z-index: 1;
      img {
        height: 20px;
        width: 20px;
        object-fit: cover;
      }
    }

    .dropzoneContainer {
      min-height: 110px;
    }

    .loading {
      border: 8px solid white;
      border-top: 8px solid vars.$color-dlcGreen;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 1s linear infinite;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.fileRow {
  height: 40px;
  border-radius: 20px;
  padding: 1.5rem;
  border: 1px solid black;
  background-color: white;
  cursor: pointer;
}
.fileNameRow {
  height: 175px;
  .inputField {
    height: 30px;
    border: none;
    outline: none;
    background-color: #f2f2f2;
  }
  .rowInput{
    border: .5px solid black;
  }

  .uploadBtn{
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    border-radius: 20px;
    background-color: vars.$color-dlcGreen;
    color: black;
    outline: none;
    border: none;

  }
}
