@use "../../scss/variables" as vars;

.businesscardRect {
  height: 250px;
  padding: 1.5rem;
  background-color: black;
  border-radius: 20px;
  width: 97.5%;
  color: white;
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: 200px;
  }

  .imageContainer {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border: 2px solid white;
    overflow: hidden;
    @media screen and (max-width: 500px) {
      height: 80px;
      width: 80px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .otherContainer {
    position: relative;
    // border: 1px solid red;
    height: 220px;
    @media screen and (max-width: 1200px) {
      height: 140px;
    }
  }

  .digitalRound {
    height: 25px;
    border: 1px solid white;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .twoBtns {
    bottom: 0;
    position: absolute;
  }
}
.previewBtn {
  width: 40%;
  background-color: white;
  color: black;
  border-radius: 20px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition-duration: 300ms;
  }

  img {
    height: 13px;
  }
}
.editBtn {
  width: 55%;
  background-color: vars.$color-dlcGreen;
  color: black;
  border-radius: 20px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition-duration: 300ms;
  }
  img {
    height: 13px;
  }
}

.shareRect {
  width: 97.5%;
  border: 1px solid black;
  height: 250px;
  border-radius: 20px;
  padding: 1.5rem;
  .shareContent {
    position: relative;
  }
  @media screen and (max-width: 1200px) {
    height: 250px;
    width: 100%;
  }

  .titleRow {
    img {
      height: 40px;
      @media screen and (max-width: 1200px) {
        height: 30px;
      }
    }
  }
  .qrRow {
    height: 100px;
    width: 100%;
    @media screen and (max-width: 1200px) {
      height: 100px;
    }
    .qrContainer {
      height: 100%;
      img {
        height: 100%;

        object-fit: cover;
      }
    }
    .shareBtn {
      height: 50px;
      border-radius: 20px;
      border: 1px solid black;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      .stringValue {
        overflow: hidden;
      }
      .copybtn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        img {
          height: 25px;
        }
      }
    }
  }
  .downloadRow {
    height: 30px;
    position: absolute;
    bottom: 0;
    .downloadBtn {
      height: 30px;
      border: 1px solid black;
      border-radius: 20px;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
        transition-duration: 300ms;
      }
      img {
        height: 20px;
      }
    }
    .whatsappIcon {
      height: 60px;
    }
    .socialIcon {
      height: 30px;
    }
    .otherOpt {
      height: 30px;
      width: 35px;
      background-color: black;
      border-radius: 20px;
      &:hover {
        transform: scale(1.1);
        transition-duration: 300ms;
      }
    }
  }
}

.exchangesRect {
  height: 350px;
  width: 97.5%;
  background-color: black;
  border-radius: 20px;
  padding: 1.5rem;
  color: white;
  position: relative;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  .titleRow {
    height: 60px;

    .descBtn {
      height: 30px;
      border-radius: 20px;
      border: 1px solid white;
    }
    img {
      height: 40px;
      @media screen and (max-width: 1200px) {
        height: 30px;
      }
    }
  }
  .contactsContainer {
    height: 200px;
    // border: 1px solid red;
  }
  .viewAllBtnContainer {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    .viewAllBtn {
      background-color: white;
      color: black;
      height: 30px;
      border-radius: 20px;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
        transition-duration: 300ms;
      }
    }
  }
}

.analyticsRect {
  height: 210px;
  background-color: black;
  color: white;
  width: 97.5%;
  border-radius: 20px;
  padding: 1.5rem;
  position: relative;
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: 220px;
  }
  .titleRow {
    height: 50px;
    // border: 1px solid red;
    img {
      height: 40px;
    }
  }
  .dataRow {
    height: 90px;
    @media screen and (max-width: 1200px) {
      height: 90px;
    }
    // border: 1px solid red;
    .tapsCounter {
      padding: 1.5rem;

      border: 1px solid white;
      border-radius: 20px;
      @media screen and (max-width: 1200px) {
        // max-width: 90px;
      }
      .firstRow {
        height: 30px;
      }
      .lastRow {
        height: 20px;
      }
    }
  }
  .viewAllBtnContainer {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;

    .viewAllBtn {
      background-color: white;
      color: black;
      height: 30px;
      border-radius: 20px;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
        transition-duration: 300ms;
      }
    }
  }
}

.referralRect {
  height: 120px;
  border: 1px solid black;
  border-radius: 20px;
  width: 100%;
  padding: 1.5rem;
  position: relative;
  .titleRow {
    height: 50px;
    img {
      height: 40px;
    }
  }
  .endButtonsRow {
    position: absolute;
    bottom: 1.5rem;
    height: 30px;
    .littleBtnWhite {
      border-radius: 20px;
      border: 1px solid black;
      img {
        height: 20px;
      }
    }
    .littleBtnBlack {
      border-radius: 20px;
      background-color: black;
      color: white;
      img {
        height: 20px;
      }
    }
  }
}
