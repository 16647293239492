@use "../../scss/variables.scss" as vars;

.pageContainer {
  //   height: 100vh;
  .boxContainer {
    height: 100%;
    color: white;
  }

  .header {
    height: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    .backBtn {
      position: absolute;
      top: 1.5rem;
      left: 0;
    //   background-color: red;
    border: 1px solid vars.$color-dlcGreen;
    // color: vars.$color-dlcGreen;
      padding: 5px;
      border-radius: 20px;
      width: 35px;
      display: flex;

      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .skipBtn {
      position: absolute;
      top: 1.5rem;
      right: 0;
      height: 30px;

      color: vars.$color-dlcGreen;
      padding: 5px;
      border-radius: 20px;
      width: 35px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
  .content {
    height: 50vh;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    .submitBtn {
      color: black;
      background-color: #b2fc02;
      border-radius: 20px;
      height: 50px;
      border: none;
      // position: absolute;
      // bottom: 10vh;
    }
    .submitBtnDisactive {
      color: black;
      background-color: #b2fc02;
      opacity: 50%;
      border-radius: 20px;
      height: 50px;
      border: none;
      // position: absolute;
      // bottom: 10vh;
    }
  }

  .inputField {
    height: 40px;
    border-radius: 20px;
    border: 1px solid white;
    background-color: black;
    color: white;
    padding: 2rem;
    outline: none;
  }

  .logoContainer {
    // border: 1px solid red;
    img {
      height: 100%;
      width: 100%;
    }
  }
}
