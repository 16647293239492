//Colors
$color-light: #fff;
$color-light-gray: #f6f6f6;
$color-light-gray-2: #ededed;
$color-light-gray-3: #e3e3e3;
$color-light-gray-4: #c7c7c7;
$color-light-gray-5: #9c9c9c;
$color-dark: #3b3c3c;
$color-dark-blue: #304053;
$color-review-blue: #7997c5;
$color-review-blue-light: #b0c9ee;
$color-review-red: #d2836c;
$color-review-yellow: #eab95a;
$color-review-green: #6cbab2;
$color-award: #7fffd4;
$color-success: #c3de72;
$color-warning: #f2b845;
$color-danger: #de8066;
$color-mandatory: #f44d1f;

//strapi colors
$color-white: #ffffff;
$color-light_grey_5: #f6f6f6;
$color-light_grey_10: #ededed;
$color-light_grey_15: #e3e3e3;
$color-light_grey_20: #dadada;
$color-light_grey_25: #d0d0d0;
$color-light_grey_30: #c6c6c6;
$color-black: #000000;
$color-dark_grey_90: #3a3a3a;
$color-dark_grey_80: #575757;
$color-dark_grey_70: #6f6f6f;
$color-dark_grey_60: #878787;
$color-dark_grey_50: #9d9d9d;
$color-dark_grey_40: #b2b2b2;
$color-dark_blue: #344759;
$color-dark_azure: #739bcd;
$color-dark_red: #b9554b;
$color-dark_yellow: #f4a019;
$color-dark_green: #a6c133;
$color-dark_cyan: #55b9b9;
$color-light_blue: #557391;
$color-light_azure: #b0c9ea;
$color-light_red: #e17d5f;
$color-light_yellow: #f5b95f;
$color-light_green: #c3e169;
$color-light_cyan: #73d7c3;
$color-dark_blue_1: #aeb5bc;
$color-dark_blue_2: #9aa3ac;
$color-dark_blue_3: #86909c;
$color-dark_blue_4: #727e8b;
$color-dark_blue_5: #5e6b7b;
$color-dark_blue_6: #4a596a;
$color-dark_blue_7: #303f51;
$color-dark_blue_8: #2b3848;
$color-dark_blue_9: #26313f;
$color-dark_blue_10: #202a35;
$color-dark_blue_11: #1b232c;
$color-dark_blue_12: #151c23;
$color-brown: #470a00;
$color-violet: #431661;
$color-orange: #e65710;
$color-dlcGreen: #b2fc02;

//Social
$color-twitter: #3a95e4;
$color-facebook: #4267b2;
$color-linkedin: #2867b2;
$color-google: #db4437;
$color-pinterest: #e60023;

//Fonts
// $font-heading: 'Montserrat', sans-serif;
$font-heading: "Helvetica";
$font-body: "Helvetica";
$font-helvetica: "Helvetica";
$font-helvetica-bold: "Helvetica-Bold";
$font-esquare: "ESquare";
$font-size-xxl: 12rem;
$font-size-xl: 5rem;
$font-size-l: 3.2rem;
$font-size-ml: 2.5rem;
$font-size-m: 2rem;
$font-size-s: 1.8rem;
$font-size-xs: 1.6rem;
$font-size-xs-16: 1.6rem;
$font-size-xxs: 1.2rem;

//Radius
$radius-s: 8px;
$radius-m: 8px;

//Timeline colors

$timeline-gray-1: #e3e3e3;
$timeline-gray-2: #dadada;
$timeline-gray-3: #d0d0d0;
$timeline-gray-4: #bcbcbc;
