.popUp {
  height: 150px;
  width: 350px;
  background-color: black;
  color: white;
  border-radius: 20px;
  position: absolute;
  top: 6vh;
  right: 3vh;
  padding: 1.5rem;
  .imageContainer {
    height: 40px;
    width: 40px;
    border: 2px solid white;
    border-radius: 50%;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .iconContainer{
    height: 25px;
    img{
        height: 25px;
    }
  }
}
