@use "../../scss/variables.scss" as vars;

.container {
  height: 80vh;
  position: relative;

  .submitBtn {
    color: black;
    background-color: #b2fc02;
    border-radius: 20px;
    height: 35px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10px;
  }
  .submitBtnDisactive {
    color: black;
    background-color: #b2fc02;
    opacity: 50%;
    border-radius: 20px;
    height: 35px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10px;
  }

  .inputField {
    height: 40px;
    border-radius: 20px;
    border: 1px solid white;
    background-color: black;
    color: white;
    padding: 2rem;
    outline: none;
  }

  .logoContainer {
    // border: 1px solid red;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .titleRow {
    min-height: 15vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // border: 1px solid red;
  }
  

  .editImageContainer {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    position: relative;
    // border:1px solid red;
    .imageContainer {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      z-index: 1;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border: 1px solid white;
        border-radius: 50%;
      }
    }
    .imageContainerEmpty {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      z-index: 1;
      overflow: hidden;
      border: 2px dashed white;
      .inputImageContainer {
        background-color: black;

        border-radius: 50%;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .updateBtn {
      position: absolute;
      z-index: 5;
      height: 50px;
      width: 50px;
      bottom: 10px;
      right: 10px;
      border-radius: 50%;
      background-color: vars.$color-dlcGreen;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .removeImg {
      position: absolute;
      z-index: 5;
      height: 30px;
      width: 30px;
      top: 15px;
      right: 15px;
      border-radius: 50%;
      background-color: rgba(255, 0, 0, 1);
      color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
  .loading {
    border: 8px solid white;
    border-top: 8px solid vars.$color-dlcGreen;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .inputRow {
    height: 30px;
    .inputContainer {
      border: 1px solid white;
      border-radius: 20px;
      .inputField {
        height: 40px;
        background-color: black;
        //   border: 0px;
        border: none;
        color: white;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: row;
        &:focus {
          outline: none;
        }
      }
    }
    .prefix {
      height: 30px;
      border-right: 1px solid white;
      // width: 100%;
    }
  }
}
