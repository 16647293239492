@use '../../scss/variables.scss' as vars;

.roundedDiv {
    height: 90px;
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding: 1.5rem;
  border-radius: 20px;
  cursor: pointer;
  img {
    height: 40px;
  }
  .rowBorded {
    border: 1px solid black;
    border-radius: 20px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-inline: 10px;
    background-color: white;
  }
  &:hover{
    background-color: vars.$color-light_grey_10;
  }
}
