@use "../../scss/variables.scss" as vars;

.firstDiv {
  padding: 1.5rem;
  border: 1px solid black;
  border-radius: 20px;
  .profileImg {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }

  .shareBtnContainer {
    height: 60px;
    .shareBtn {
      height: 40px;
      background-color: black;
      color: vars.$color-dlcGreen;
      border-radius: 20px;
      .shareImg {
        height: 25px;
        object-fit: cover;
      }
    }
  }
  .dataRow {
    height: 80px;
    .smallCont {
      height: 100%;
      border-radius: 20px;
      border: 1px solid black;
      padding: 1.5rem;
    }
  }
}
.savedCardDiv {
  padding: 1.5rem;
  background-color: black;
  color: white;
  border-radius: 20px;
  width: 97.5%;
  @media screen and (max-width: 750px) {
    width: 100%;
  }
  .titleRow {
    img {
      height: 50px;
    }
  }
  .cardCounter {
    border: 1px solid white;
    border-radius: 20px;
    padding: 1.5rem;
  }
}

.savedMaterialsDiv {
  padding: 1.5rem;
  background-color: white;
  color: black;
  border-radius: 20px;
  border: 1px solid black;
  width: 97.5%;
  @media screen and (max-width: 750px) {
    width: 100%;
  }
  .titleRow {
    img {
      height: 50px;
    }
  }
  .materialSavedDiv {
    border: 1px solid black;
    border-radius: 20px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    @media screen and (max-width: 500px) {
      width: 120px;
      margin-top: 10px;
    }

    img {
      height: 35px;
    }
  }
}

.co2Div {
  width: 97.5%;
  border: 1px solid black;
  background-color: black;
  color: white;
  border-radius: 20px;
  padding: 1.5rem;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 750px) {
    width: 100%;
    min-height: 250px;
  }
  .thankYou {
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
    z-index: 1;
  }
  .treeImg {
    position: absolute;
    bottom: 0;
    right: -40px;
    height: 200px;
    z-index: 0;
    width: 170px;
  }
}
