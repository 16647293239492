@use "./variables" as vars;

//Radius
.radius-m {
  border-radius: vars.$radius-m;
}

.radius-s {
  border-radius: vars.$radius-s;
}

//Fonts
.font-heading {
  font-family: vars.$font-heading !important;
  // transform: scaleX(0.96);
}
.font-body {
  font-family: vars.$font-body !important;
}

//Colors
.color-light {
  color: vars.$color-light !important;
}
.color-dlcgreen {
  color: vars.$color-dlcGreen !important;
}
.color-light-gray {
  color: vars.$color-light-gray !important;
}
.color-light-gray-2 {
  color: vars.$color-light-gray-2 !important;
}
.color-light-gray-3 {
  color: vars.$color-light-gray-5 !important;
}
.color-dark {
  color: vars.$color-dark !important;
}
.color-dark-blue {
  color: vars.$color-dark-blue !important;
}
.color-blue {
  color: vars.$color-review-blue !important;
}
.color-red {
  color: vars.$color-review-red !important;
}
.color-yellow {
  color: vars.$color-review-yellow !important;
}
.color-green {
  color: vars.$color-review-green !important;
}
.color-success {
  color: vars.$color-success !important;
}
.color-warning {
  color: vars.$color-warning !important;
}
.color-danger {
  color: vars.$color-danger !important;
}
.color-mandatory {
  color: vars.$color-mandatory !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

//strapi colors
.color-white {
  color: vars.$color-white !important;
}
.color-light_grey_5 {
  color: vars.$color-light_grey_5 !important;
}
.color-light_grey_10 {
  color: vars.$color-light_grey_10 !important;
}
.color-light_grey_15 {
  color: vars.$color-light_grey_15 !important;
}
.color-light_grey_20 {
  color: vars.$color-light_grey_20 !important;
}
.color-light_grey_25 {
  color: vars.$color-light_grey_25 !important;
}
.color-light_grey_30 {
  color: vars.$color-light_grey_30 !important;
}
.color-black {
  color: vars.$color-black !important;
}
.color-dark_grey_90 {
  color: vars.$color-dark_grey_90 !important;
}
.color-dark_grey_80 {
  color: vars.$color-dark_grey_80 !important;
}
.color-dark_grey_70 {
  color: vars.$color-dark_grey_70 !important;
}
.color-dark_grey_60 {
  color: vars.$color-dark_grey_60 !important;
}
.color-dark_grey_50 {
  color: vars.$color-dark_grey_50 !important;
}
.color-dark_grey_40 {
  color: vars.$color-dark_grey_40 !important;
}
.color-dark_blue {
  color: vars.$color-dark_blue !important;
}
.color-dark_azure {
  color: vars.$color-dark_azure !important;
}
.color-dark_red {
  color: vars.$color-dark_red !important;
}
.color-dark_yellow {
  color: vars.$color-dark_yellow !important;
}
.color-dark_green {
  color: vars.$color-dark_green !important;
}
.color-dark_cyan {
  color: vars.$color-dark_cyan !important;
}
.color-dark_cyan {
  color: vars.$color-dark_cyan !important;
}
.color-light_blue {
  color: vars.$color-light_blue !important;
}
.color-light_azure {
  color: vars.$color-light_azure !important;
}
.color-light_red {
  color: vars.$color-light_red !important;
}
.color-light_yellow {
  color: vars.$color-light_yellow !important;
}
.color-light_green {
  color: vars.$color-light_green !important;
}
.color-light_cyan {
  color: vars.$color-light_cyan !important;
}
.color-dark_blue_1 {
  color: vars.$color-dark_blue_1 !important;
}
.color-dark_blue_2 {
  color: vars.$color-dark_blue_2 !important;
}
.color-dark_blue_3 {
  color: vars.$color-dark_blue_3 !important;
}
.color-dark_blue_4 {
  color: vars.$color-dark_blue_4 !important;
}
.color-dark_blue_5 {
  color: vars.$color-dark_blue_5 !important;
}
.color-dark_blue_6 {
  color: vars.$color-dark_blue_6 !important;
}
.color-dark_blue_7 {
  color: vars.$color-dark_blue_7 !important;
}
.color-dark_blue_8 {
  color: vars.$color-dark_blue_8 !important;
}
.color-dark_blue_9 {
  color: vars.$color-dark_blue_9 !important;
}
.color-dark_blue_10 {
  color: vars.$color-dark_blue_10 !important;
}
.color-dark_blue_11 {
  color: vars.$color-dark_blue_11 !important;
}
.color-dark_blue_12 {
  color: vars.$color-dark_blue_12 !important;
}

//Backgrounds
.bg-light {
  background-color: vars.$color-light !important;
}
.bg-light-gray {
  background-color: vars.$color-light-gray !important;
}
.bg-light-gray-2 {
  background-color: vars.$color-light-gray-2 !important;
}
.bg-light-gray-3 {
  background-color: vars.$color-light-gray-5 !important;
}
.bg-dark {
  background-color: vars.$color-dark !important;
}
.bg-dark-blue {
  background-color: vars.$color-dark-blue !important;
}
.bg-blue {
  background-color: vars.$color-review-blue !important;
}
.bg-red {
  background-color: vars.$color-review-red !important;
}
.bg-yellow {
  background-color: vars.$color-review-yellow !important;
}
.bg-green {
  background-color: vars.$color-review-green !important;
}
.bg-success,
.bg-running {
  background-color: vars.$color-success !important;
}
.bg-warning {
  background-color: vars.$color-warning !important;
}
.bg-danger,
.bg-closed {
  background-color: vars.$color-danger !important;
}
.bg-brown {
  background-color: vars.$color-brown !important;
}
.bg-violet {
  background-color: vars.$color-violet !important;
}
.bg-orange {
  background-color: vars.$color-orange !important ;
}

//Spacing
.pt-7 {
  padding-top: 5rem !important;
}
.pb-7 {
  padding-bottom: 5rem !important;
}
.py-7 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-10 {
  padding-top: 7rem !important;
}
.pb-10 {
  padding-bottom: 7rem !important;
}
.py-10 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.mt-6 {
  margin-top: 4rem !important;
}
.mb-6 {
  margin-bottom: 4rem !important;
}
.my-6 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.mt-7 {
  margin-top: 5rem !important;
}
.mb-7 {
  margin-bottom: 5rem !important;
}
.my-7 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.mt-10 {
  margin-top: 7rem !important;
}
.mb-10 {
  margin-bottom: 7rem !important;
}
.my-10 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -2rem !important;
}
