.logoContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border-bottom: 1px solid #b2fc02;
  img {
    // height: 100%;
    padding: 3rem;
    width: 100%;
    object-fit: cover;
  }
}
.logoContainerWhite {
  //   height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  //   border-bottom: 1px solid black;
  position: absolute;
  // top: 1rem;
  left: 0;
  img {
    // height: 100%;
    padding: 1.5rem;
    width: 100%;
    // border: 1px solid red;
    object-fit: cover;
    @media screen and (max-width: 800px) {
      // width: 30vw;
    }  
  }
}

.title {
  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }
  @media screen and (min-width: 1000px) {
    font-size: 2.5rem;
  }
}

.infoContainer {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 3rem;
  border-bottom: 1px solid black;
  @media screen and (max-width: 1200px) {
    justify-content: end;
  }
}

.contactBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #b2fc02;
  border-radius: 20px;
  height: 27px;
  width: 120px;
  color: black;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition-duration: 300ms;
    
  }
  @media screen and (max-width: 800px) {
  }
  img {
    height: 15px;
  }
}

.iconInfo {
  height: 30px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition-duration: 300ms;
  }
}
.iconUserContainer {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition-duration: 300ms;
  }
  .iconUser {
    // border: 1px solid red;
    height: 18px;
    width: 15px;
  }
}
