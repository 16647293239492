.icon {
  height: 20px;
  @media screen and (max-width: 800px) {
    height: 30px;
  }
}
.main {
  position: fixed;
}

.mobileMenu {
  height: 50px;
  position: fixed;
  bottom: 0;
  background-color: black;
  z-index: 2;
}
