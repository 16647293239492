.pageContainer {
    //   height: 100vh;
    .boxContainer {
      height: 100%;
      color: white;
    }
  
    .header {
      height: 25vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
    }
    .content {
      height: 50vh;
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
  
      .submitBtnDisactive {
        color: black;
        background-color: #b2fc02;
        opacity: 50%;
        border-radius: 20px;
        height: 50px;
        border: none;
        // position: absolute;
        // bottom: 10vh;
      }
    }
  
    .inputField {
      height: 40px;
      border-radius: 20px;
      border: 1px solid white;
      background-color: black;
      color: white;
      padding: 2rem;
    }
  
    .logoContainer {
      // border: 1px solid red;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .submitBtn {
      color: black;
      background-color: #b2fc02;
      border-radius: 20px;
      height: 50px;
      border: none;
      // position: absolute;
      // bottom: 10vh;
    }
  }
  